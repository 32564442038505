import React, {useEffect, useState} from "react";
import {getDatabase, ref, onValue} from "firebase/database";
import {Grid} from "@mui/material";
import Project from "../components/Project.js"
import ProjectSkeleton from "../components/ProjectSkeleton";

const PortfolioPage = () => {
    const [projects, updateProjects] = useState([]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        const projectDb = ref(getDatabase(), 'projects');
        onValue(projectDb, (snapshot) => {
            const projectList = snapshot.val();
            const projects = [];
            for (let id in projectList) {
                projects.push(projectList[id]);
            }
            updateProjects(projects);
            setLoading(false);
            window.scrollTo(0,0);
        });
    }, []);
    return projects&&
    <React.Fragment>
        {
            isLoading&&
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-evenly">
                <Grid item>
                    <ProjectSkeleton/>
                </Grid>
                <Grid item>
                    <ProjectSkeleton/>
                </Grid>
                <Grid item>
                    <ProjectSkeleton/>
                </Grid>
                <Grid item>
                    <ProjectSkeleton/>
                </Grid>
                <Grid item>
                    <ProjectSkeleton/>
                </Grid>
                <Grid item>
                    <ProjectSkeleton/>
                </Grid>
            </Grid>
        }
        {
            !isLoading&&
            <Grid container direction="row" justifyContent="space-evenly" style={{marginBottom: "75px"}}>
                {projects.map((project, index) => {
                    return <Grid item key={index}>
                        <Project
                            id={project.id}
                            title={project.title}
                            image={project.image}
                            imageDescription={project.altText}
                            description={project.description}
                        />
                    </Grid>
                })}
            </Grid>
        }
    </React.Fragment>;
};
export default PortfolioPage;