import React from "react";
import {Card, CardMedia, Grid, Typography} from "@mui/material";
import "../scss/about.scss";
import {makeAboutCardStyle} from "../components/Styles";

const ResumePage = () => {
    return (
        <React.Fragment>
            <div
                style={{"margin-top": '3.75vw', display: "flex", "justify-content": "center", "align-items": "center"}}>
                <iframe title="Jared Brown Resume" frameBorder={0} src={require('../assets/Jared Brown Resume.pdf')} style={{
                    width: "60vw",
                    height: "70vw",
                    marginBottom: "75px"
                }}/>
            </div>
        </React.Fragment>
    )
};
export default ResumePage;