import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {AppBar, Button, Grid, Slide, Toolbar, Typography, useScrollTrigger} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import resume from "../assets/Jared Brown Resume.pdf"

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: '10'
    },
}));

function ScrollTop(props) {
    const {children, window} = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 900,
    });

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}

function HideOnScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger();
    return (
        <div style={{position: 'sticky', zIndex: '10'}}>
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
            <Slide mountOnEnter direction="down" in={trigger}>
                <AppBar color="primary">
                    <Toolbar>
                        <Grid container alignItems="flex-start" justifyContent="flex-start">
                            <Button color="inherit" href={`${window.location.origin.toString()}`} size="large"
                                    style={{marginRight: 40}}>
                                <img alt="Jared Brown" src={require('../assets/JB logo.png')} style={{
                                    width: 72,
                                    height: 38,
                                }}/>
                            </Button>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button color="inherit" href={`${window.location.origin.toString()}/about`} size="large"
                                        item>
                                    <Typography variant="h6" sx={{fontSize: '18px'}}>About</Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="inherit" href={`${window.location.origin.toString()}`} size="large" item>
                                    <Typography variant="h6" sx={{fontSize: '18px'}}>Portfolio</Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                {useMediaQuery('(min-width:992px)') &&
                                    <Button color="inherit" href={`${window.location.origin.toString()}/resume`}
                                            size="large">
                                        <Typography variant="h6" sx={{fontSize: '18px'}}>Résumé</Typography>
                                    </Button>
                                }
                                {!useMediaQuery('(min-width:992px)') &&
                                    <Button color="inherit" href={resume} target="blank"
                                            size="large">
                                        <Typography variant="h6" sx={{fontSize: '18px'}}>Résumé</Typography>
                                    </Button>
                                }
                            </Grid>
                            <Grid item>
                                <Button color="inherit" href={`${window.location.origin.toString()}/contact`}
                                        size="large" item>
                                    <Typography variant="h6" sx={{fontSize: '18px'}}>Contact</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Slide>
        </div>
    );
}

const LargeAppNav = (props) => {
    return (
        <React.Fragment>
            <CssBaseline/>
            <HideOnScroll number={140}>
                <AppBar position="sticky" color="primary">
                    <Toolbar disableGutters>
                        <div style={{width: '100%'}}>
                            <div>
                                <Grid container direction="row" alignItems="center" justifyContent="space-around">
                                    <a href={`${window.location.origin.toString()}`}>
                                        <Avatar alt="Jared Brown" src={require('../assets/JaredSmall.jpg')} style={{
                                            width: "7vw",
                                            height: '7vw',
                                            marginTop: 15,
                                            maxWidth: 200,
                                            minWidth: '50px',
                                            maxHeight: 200,
                                            minHeight: '50px',
                                            border: 0,
                                            objectFit: 'cover',

                                        }}/>
                                    </a>
                                </Grid>
                                <Grid container direction="column" alignItems="center" justifyContent="center"
                                      style={{paddingTop: 10}}>
                                    <Typography variant="h6" sx={{fontSize: '14px'}}>Jared Brown | Software
                                        Developer</Typography>
                                </Grid>
                            </div>
                            <div style={{backgroundColor: '#217f7e', marginTop: 16}}>
                                <Grid container direction="row" alignItems="flex-start" justifyContent="space-around"
                                      style={{paddingLeft: '20vw', paddingRight: '20vw', backgroundColor: '#217f7e'}}>
                                    <Grid item>
                                        <Button color="inherit" href={`${window.location.origin.toString()}/about`}
                                                size="large">
                                            <Typography variant="h6" sx={{}}>About</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color="inherit" href={`${window.location.origin.toString()}`}
                                                size="large">
                                            <Typography variant="h6" sx={{}}>Portfolio</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {useMediaQuery('(min-width:992px)') &&
                                            <Button color="inherit" href={`${window.location.origin.toString()}/resume`}
                                                    size="large">
                                                <Typography variant="h6" sx={{}}>Résumé</Typography>
                                            </Button>
                                        }
                                        {!useMediaQuery('(min-width:992px)') &&
                                            <Button color="inherit" href={resume} target="blank"
                                                    size="large">
                                                <Typography variant="h6" sx={{}}>Résumé</Typography>
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Button color="inherit" href={`${window.location.origin.toString()}/contact`}
                                                size="large">
                                            <Typography variant="h6" sx={{}}>Contact</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <div id="back-to-top-anchor"/>
            <ScrollTop {...props}>
                <Fab color='secondary' size="large" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon htmlColor="white"/>
                </Fab>
            </ScrollTop>
        </React.Fragment>
    );
}

const HamburgerMenu = () => {

    const [state, setState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };

    const MyList = () => {
        return <div
            style={{width: 250, color: "white", backgroundColor: "#212121", height: "100%"}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button onClick={() => window.open(`${window.location.origin.toString()}/about`, "_self")}>
                    <Typography style={{fontSize: '1.5rem'}}>About</Typography>
                </ListItem>
                <ListItem button onClick={() => window.open(`${window.location.origin.toString()}`, "_self")}>
                    <Typography style={{fontSize: '1.5rem'}}>Portfolio</Typography>
                </ListItem>
                {
                    useMediaQuery('(min-width:992px)') &&
                    <ListItem button
                              onClick={() => window.open(`${window.location.origin.toString()}/resume`, "_self")}>
                        <Typography style={{fontSize: '1.5rem'}}>Résumé</Typography>
                    </ListItem>
                }
                {
                    !useMediaQuery('(min-width:992px)') &&
                    <ListItem button onClick={() => window.open(resume, "_blank")}>
                        <Typography style={{fontSize: '1.5rem'}}>Résumé</Typography>
                    </ListItem>
                }
                <ListItem button onClick={() => window.open(`${window.location.origin.toString()}/contact`, "_self")}>
                    <Typography style={{fontSize: '1.5rem'}}>Contact</Typography>
                </ListItem>
            </List>
        </div>
    };

    return (
        <div>
            <React.Fragment>
                <IconButton onClick={toggleDrawer(true)} size="large"><MenuIcon/></IconButton>
                <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
                    <MyList>
                    </MyList>
                </Drawer>
            </React.Fragment>
        </div>
    );
};

const MobileAppNav = (props) => {
    return (
        <div style={{position: 'sticky', zIndex: '10', marginBottom: "3.75vh"}}>
            <AppBar color="primary">
                <Toolbar>
                    <Grid container alignItems="flex-start" justifyContent="flex-start">
                        <Button color="inherit" href={`${window.location.origin.toString()}`} size="large"
                                style={{marginRight: 40}}>
                            <img alt="Jared Brown" src={require('../assets/JB logo.png')} style={{
                                width: 'min(72px, 16vw)',
                                height: 'min(38px, 8vw)',
                            }}/>
                        </Button>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <HamburgerMenu/>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

const HomeAppNav = () => {
    const mobile = !useMediaQuery('(min-width:992px)');
    if (mobile) {
        return (<MobileAppNav/>)
    } else {
        return (<LargeAppNav/>);
    }
};
export default HomeAppNav;