import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
    responsiveFontSizes
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import 'typeface-roboto';
let theme = createTheme({
    palette: {
        primary: {
            light: '#fff',
            main: '#219897',
            dark: '#000',
        },
        secondary: {
            main: '#502386',
        },
        background: {
            default: '#121212',
            lighter: '#212121'
        }
    },
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
        useNextVariants: true,
        h6: {
            fontSize: '0.95rem'
        },
        body1: {
            fontSize: '1.5rem'
        }
    },
    breakpoints: {
        values: {
            mobile: 600,
            project: 992,
            portfolio: 577
        }
    }
});
theme = responsiveFontSizes(theme);
ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

serviceWorker.unregister();
