import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import {makeProjectStyle} from "./Styles";


export default function Project({image, altText, title, description, id}) {
    const projectStyle = makeProjectStyle();
    return (
            <Card className={projectStyle.root} style={{color: "white", height:"90%"}} raised={true}>
                <CardMedia
                    className={projectStyle.media}
                    image={image}
                    title={altText}
                />
                <CardContent className={projectStyle.content}>
                    <Typography gutterBottom variant="h5" style={{color: "white"}}>
                        <strong>{title}</strong>
                    </Typography>
                    <Typography variant="body2" style={{color: "white"}}>
                        {description}
                    </Typography>
                </CardContent>
                <CardActions className={projectStyle.button}>
                    <Button href={`${window.location.origin.toString()}/project/${id}`} size="small"
                            style={{color: "#3fcccb"}}>
                        Learn More
                    </Button>
                </CardActions>
            </Card>

    );
}