import React from "react";
import {makeHomeStyle} from "../components/Styles";
import {Card, Grid, Typography} from "@mui/material";
import PortfolioPage from "./PortfolioPage";

const HomePage = () => {
    const homeStyle = makeHomeStyle();
    return (
<React.Fragment>
        <Grid container direction="row" alignItems="flex-start" justifyContent="space-around" className={homeStyle.topSect}>
            <Grid item alignItems="flex-middle">
                <Typography align={"left"} variant={"h2"} sx={{padding: '20px', color: 'white', fontSize: 'min(2.375rem, 7vw)'}}>
                    <strong>Hello, I'm Jared Brown.</strong>
                    <br/>
                </Typography>
                <Typography align={"left"} variant={"h3"} sx={{padding: '20px', color: 'white', fontSize: 'min(2rem, 6vw)'}}>
                    I'm a web developer.
                </Typography>
            </Grid>
        </Grid>
        <PortfolioPage/>
</React.Fragment>
    );
};
export default HomePage;