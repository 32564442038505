import React from "react";
import {Card, Grid} from "@mui/material";
import "../scss/about.scss";
import makeStyles from '@mui/styles/makeStyles';
import Github from '@mui/icons-material/GitHub';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {makeContactCardStyle} from "../components/Styles";

const ContactPage = () => {
    const contactPageStyle = makeContactCardStyle();
    return (
        <React.Fragment>
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-evenly">
                <Grid item sx={{width: "100%"}}>
                    <Card className={contactPageStyle.root} raised={true} style={{textAlign: 'center', margin: "75px auto 0 auto"}}>
                        <Link href={"mailto: jaredbrowndev@gmail.com"} variant={"body1"} className={contactPageStyle.link}>jaredbrowndev@gmail.com</Link>
                        <Grid container direction="row" alignItems="center" justifyContent="space-evenly">
                            <Grid item>
                                <Button align={"center"} href="https://github.com/jtbrown2-bsu/">
                                    <Github fontSize='large' htmlColor="white"/>
                                </Button>
                                <Button align={"center"} href={"https://www.linkedin.com/in/jared-brown-39641a208"}>
                                    <LinkedIn fontSize='large' htmlColor="white"/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
export default ContactPage;