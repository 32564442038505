import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import {makeProjectStyle} from "./Styles";
import Skeleton from '@mui/material/Skeleton';

export default function ProjectSkeleton() {
    const projectStyle = makeProjectStyle();
    return (
        <Card className={projectStyle.root} style={{color: "white"}} raised={true}>
            <Skeleton animation="wave" variant="rectangular" height={300} />
            <CardContent className={projectStyle.content}>
                <Skeleton animation="wave" height={15} style={{ marginBottom: "0.35em" }} />
                <br/>
                <Skeleton animation="wave" height={10} style={{ marginBottom: "0.35em" }} />
                <Skeleton animation="wave" height={10} style={{ marginBottom: "0.35em" }} />
                <Skeleton animation="wave" height={10} style={{ marginBottom: "0.35em" }} />
                <Skeleton animation="wave" height={10} width="80%" />
            </CardContent>
            <CardActions>
                <Button disabled size="small" style={{color: "#3fcccb"}}>
                    Loading
                </Button>
            </CardActions>
        </Card>
    );
}