import makeStyles from '@mui/styles/makeStyles';

const backgroundColor = '#474141'; //This is for cards, the actual one is in the theme index.js

export const makePortfolioCardStyle = makeStyles(theme => ({
    root: {

    }
}));

export const makeAboutCardStyle = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.lighter,
        [theme.breakpoints.up('project')]: {
            width: "42vw",
            marginTop: '75px',
            maxWidth: 500
        },
        [theme.breakpoints.down('project')]: {
            display: "none"
        },
        marginBottom: "75px"
    },
    description: {
        backgroundColor: theme.palette.background.lighter,
        [theme.breakpoints.up('project')]: {
            width: "42vw",
            marginTop: '75px',
            maxWidth: 800
        },
        [theme.breakpoints.down('project')]: {
            maxWidth: "80%",
            minWidth: "240px",
            margin: '75px auto 75px'
        },
        marginBottom: "75px"
    },
    text: {
        padding: 20,
        color: 'white',
        [theme.breakpoints.up('project')]: {
            fontSize: '1.85rem'
        },
        [theme.breakpoints.down('project')]: {
            fontSize: 'min(1.85rem, 6vw)'
        }
    },
    page: {
        [theme.breakpoints.up('project')]: {
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-evenly"
        },
        [theme.breakpoints.down('project')]: {
            display: "block"
        }
    }
}));

export const makeHomeStyle = makeStyles(theme => ({
    root: {
        maxWidth: 750,
        width: '50vw',
        "margin-top": '75px',
        backgroundColor: backgroundColor,
        color: '#61dafb',
        "font-family": 'Roboto',
    },
    topSect: {
        backgroundColor: theme.palette.background.lighter,
        [theme.breakpoints.up('project')]: {
            padding: '100px',
        },
        [theme.breakpoints.down('project')]: {
            padding: '25px',
            marginTop: '50px',
            h2: {
                fontSize: 'min(2.375rem, 6vw)'
            },
            h3: {
                fontSize: 'min(2rem, 5vw)'
            }
        },
    }
}));

export const makeProjectStyle = makeStyles(theme => ({
    root: {
        marginTop: "75px",
        width: '26vw',
        minWidth: '280px',
        backgroundColor: theme.palette.background.lighter,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('portfolio')]: {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    container: {
        "margin-top": "75px",
    },
    content: {
        "flex-grow": 1
    },
    media: {
        height: 0,
        paddingTop: '62%'
    },
    button: {
        "margin-top": "auto"
    }
}));

export const makeProjectCardStyle = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('project')]: {
            maxWidth: "44vw",
            marginTop: '75px',
            minWidth: "355px"
        },
        [theme.breakpoints.down('project')]: {
            maxWidth: "80%",
            minWidth: "240px",
            margin: '75px auto 75px'
        },
        backgroundColor: theme.palette.background.lighter,
        color: '#61dafb',
        "font-family": 'Roboto',
        marginBottom: "75px"
    },
    title: {
        padding: 20,
        color: 'white',
        [theme.breakpoints.up('project')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down('project')]: {
            fontSize: '2.4rem',
        },
        fontWeight: '600'
    },
    description: {
        padding: 20,
        color: 'white',
        [theme.breakpoints.up('project')]: {
            fontSize: '2.5rem',
        },
        [theme.breakpoints.down('project')]: {
            fontSize: '2rem',
        },
        fontWeight: '600'
    }
}));

export const makeCardImageSticky = makeStyles(theme => ({
    root: {
        "@media (min-width:1080px)": {"align-self": "flex-start", position: "sticky", top: "3.75vw"},
    }
}));

export const makeContactCardStyle = makeStyles(theme => ({
    root: {
        maxWidth: 500,
        width: "80%",
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: '75px',
        borderRadius: 6,
        backgroundColor: theme.palette.background.lighter,
        color: '#61dafb',
        "font-family": 'Roboto',
    },
    link: {
        [theme.breakpoints.up('project')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('project')]: {
            fontSize: 'min(5vw, 1.5rem)',
        },
        color: "white"
    }
}));

export const makeProjectPageStyle = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('project')]: {
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-evenly"
        },
        [theme.breakpoints.down('project')]: {
            display: "block"
        }
    }
}));