import React from "react";
import {Card, CardMedia, Grid, Typography} from "@mui/material";
import "../scss/about.scss";
import {makeAboutCardStyle} from "../components/Styles";

const AboutPage = () => {
    const aboutCardStyle = makeAboutCardStyle();
    return (
        <React.Fragment>
            <Grid container className={aboutCardStyle.page}>
                <Grid item>
                    <Card className={aboutCardStyle.root} raised={true}>
                        <CardMedia
                            component="img"
                            image={require('../assets/Jared.jpg')}
                            height="auto"
                            title="Jared Brown"
                            alt="Jared Brown"
                        />
                    </Card>
                </Grid>

                <Grid item style={{
                    '-webkit-align-self': 'center',
                    'align-self': 'center'
                }}>
                    <Card className={aboutCardStyle.description} raised={true}>
                        <Typography align={"center"} variant={"h6"} className={aboutCardStyle.text}>
                            I graduated from Ball State University in May 2023 with a bachelor's degree in computer science.
                        </Typography>
                        <Typography align={"center"} variant={"h6"} className={aboutCardStyle.text}>
                            On campus, I worked at the Digital Corps, where students of various
                            creative technical fields would improve and use their skills to solve the real-world
                            problems of clients related to the university.
                            <br/>
                            <br/>
                            At the Corps, I was a Master on the Development Team, the highest position available to undergraduate students at the Corps.
                        </Typography>
                        <Typography align={"center"} variant={"h6"} className={aboutCardStyle.text}>
                            I also interned at Solutions4Ebiz, a company that makes websites and other tech solutions for clients such as internet service providers, as a Developer throughout 2021 and 2022.
                        </Typography>
                    </Card>
                </Grid>

            </Grid>
        </React.Fragment>
    );
};
export default AboutPage;