import React, {useEffect, useState} from "react";
import {getDatabase, ref, onValue} from "firebase/database";
import {Card, CardMedia, Grid, Typography} from "@mui/material";
import "../scss/about.scss";
import {useParams} from "react-router";
import {makeCardImageSticky, makeProjectCardStyle, makeProjectPageStyle} from "../components/Styles";
import Divider from "@mui/material/Divider";
import Skeleton from '@mui/material/Skeleton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from "@mui/material/Snackbar";
import Link from "@mui/material/Link";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PortfolioPage = () => {
    let { id } = useParams();
    const [project, updateProject] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        const projectDb = ref(getDatabase(), 'projects');
        onValue(projectDb, (snapshot) => {
            const projectList = snapshot.val();
            let projectTemporary = null;
            for (let num in projectList) {
                if(projectList[num].id.toString() === id) {
                    projectTemporary = projectList[num];
                }
            }
            if(projectTemporary === null) {
                setIsEmpty(true);
            }
            updateProject(projectTemporary);
        })}, []);

    const projectCardStyle = makeProjectCardStyle();
    const imageCardStickyStyle = makeCardImageSticky();
    const projectStyle = makeProjectPageStyle();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsEmpty(false);
    };

    return (
        <React.Fragment>
            {project &&
            <Grid container className={projectStyle.root}>
                <Grid item className={imageCardStickyStyle.root}>
                    <Card className={projectCardStyle.root} raised={true}>
                        <CardMedia
                            component="img"
                            image={project.image}
                            title={project.altText}
                            alt={project.altText}
                        />
                    </Card>
                </Grid>

                <Grid item>
                    <Card className={projectCardStyle.root} raised={true}>
                        <Typography align={"center"} variant={"h6"} className={projectCardStyle.title}>
                            {project.title}
                        </Typography>
                        <Divider variant="middle" light style={{backgroundColor: "gray"}}/>
                        <Typography align={"center"} variant={"h6"} className={projectCardStyle.description}>
                            Description
                        </Typography>
                        <Typography align={"left"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '1.5rem', fontWeight: '400'}}>
                            {project.longDescription}
                        </Typography>
                        <Divider variant="middle" light style={{backgroundColor: "gray"}}/>
                        <Typography align={"center"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '2.5rem', fontWeight: '600'}}>
                            Tools Used
                        </Typography>
                        {Object.values(project.tools).map((tool, index) => {
                            return <Typography align={"left"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '1.5rem', fontWeight: '600'}}>
                                {tool.name}
                            </Typography>
                        })}

                        {project.links&&
                            <div style={{marginBottom: 20}}>
                                <Divider variant="middle" light style={{backgroundColor: "gray"}}/>
                                <Typography align={"center"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '2.5rem', fontWeight: '600'}}>
                                    Links
                                </Typography>
                                {Object.values(project.links).map((link, index) => {
                                    return <Link target={"_blank"} href={link.link} align={"left"} variant={"h6"} style={{padding: 20, color: '#3fcccb', fontSize: '1.5rem', fontWeight: '600'}}>
                                        {link.name}
                                    </Link>
                                })}
                            </div>
                        }
                    </Card>
                </Grid>
            </Grid>}
            {!project &&
            <Grid container className={projectStyle.root}>
                <Grid item className={imageCardStickyStyle.root}>
                    <Card className={projectCardStyle.root} raised={true}>
                        <Skeleton animation="wave" variant="rectangular" width={850} height={450} />
                    </Card>
                </Grid>

                <Grid item>
                    <Card className={projectCardStyle.root} raised={true} style={{width: "44vw", height: 800}}>
                        <Typography align={"center"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '3rem', fontWeight: '600'}}>
                            <Skeleton animation="wave" height={75} top={50}/>
                        </Typography>

                        <Divider variant="middle" light style={{backgroundColor: "gray"}}/>

                        <Typography align={"center"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '2.5rem', fontWeight: '600'}}>
                            <Skeleton animation="wave" height={63}/>
                        </Typography>

                        <Typography align={"left"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '1.5rem', fontWeight: '400'}}>
                            <Skeleton animation="wave" height={38}/>
                            <Skeleton animation="wave" height={38}/>
                            <Skeleton animation="wave" height={38}/>
                            <Skeleton animation="wave" height={38}/>
                        </Typography>

                        <Divider variant="middle" light style={{backgroundColor: "gray"}}/>

                        <Typography align={"center"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '2.5rem', fontWeight: '600'}}>
                            <Skeleton animation="wave" height={63}/>
                        </Typography>

                        <Typography align={"left"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '1.5rem', fontWeight: '400'}}>
                            <Skeleton animation="wave" height={38}/>
                        </Typography>

                        <Divider variant="middle" light style={{backgroundColor: "gray"}}/>

                        <Typography align={"center"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '2.5rem', fontWeight: '600'}}>
                            <Skeleton animation="wave" height={63}/>
                        </Typography>

                        <Typography align={"left"} variant={"h6"} style={{padding: 20, color: 'white', fontSize: '1.5rem', fontWeight: '400'}}>
                            <Skeleton animation="wave" height={38}/>
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            }
            {isEmpty &&
            <Snackbar open={isEmpty} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Sorry, but there is no project at this URL.
                </Alert>
            </Snackbar>
            }
        </React.Fragment>
    );
};
export default PortfolioPage;