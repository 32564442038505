import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './scss/App.scss';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import HomeAppNav from "./components/HomeAppNav";
import ContactPage from "./pages/ContactPage";
import PortfolioPage from "./pages/PortfolioPage";
import {initializeApp} from "firebase/app"
import FirebaseConfig from "./firebase.config";
import ProjectPage from "./pages/ProjectPage";
import ResumePage from "./pages/ResumePage";

function App() {
    initializeApp(FirebaseConfig);
    return (
        <React.Fragment>
                <HomeAppNav/>
                <Routes>
                    <Route path="/"            element={<HomePage/>}/>
                    <Route path="/about"       element={<AboutPage/>}/>
                    <Route path="/contact"     element={<ContactPage/>}/>
                    <Route path="/project/:id" element={<ProjectPage/>}/>
                    <Route path="/resume"      element={<ResumePage/>}/>
                </Routes>
        </React.Fragment>
    );
}

export default App;
